html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  background-color: #fff;
}

body * {
  box-sizing: border-box;
}
